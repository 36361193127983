import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize, tap } from 'rxjs';
import { LoadingService } from '../services/loading.service';

export const requestInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoadingService);
  if (isNoNeedLoaderList(req.url)) {
    loadingService.loadingOn();
  }
  return next(req).pipe(
    finalize(() => {
      loadingService.loadingOff();
    })
  );
};


function isNoNeedLoaderList (url: string) {
  return url.indexOf('interventions') === -1
    && url.indexOf('categorie_logement') === -1
    && url.indexOf('api-adresse.data.gouv.fr') === -1
    && url.indexOf('request-received') === -1;
}
